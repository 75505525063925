import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { FC } from "react";
import Tilt from "react-tilt";
import Button from "../components/Button";
import {
  ChildrenProps,
  MainContainer,
  PageContainer,
  SkewMainContainer,
} from "../components/Container";
import ContentfulImage from "../components/ContentfulImage";
import Footer from "../components/Footer";
import Header, { ThemeEnum } from "../components/Header";
import Metadata from "../components/Metadata";
import SearchInput from "../components/SearchInput";
import SkewGradientBg from "../components/SkewGradientBg";
import { PageDescribe, PageTitle } from "../components/Title";
import {
  NewsCover,
  NewsIcon,
  SVGNewsCoverBack,
  SVGNewsCoverFront,
} from "../images/news";
import { usePageMetadata } from "../metadata/page";
import { useMediaScreen } from "../utils/utils";
import LearnMoreView from "../Views/LearnMoreView";
import { PageTitleWithIcon } from "./price";

export type INewsItem = NewsPageQuery["allContentfulNews"]["nodes"][number];

const NewsItem = ({ newsItem }: { newsItem: INewsItem }) => {
  return (
    <a
      href={newsItem.linkUrl}
      target="_blank"
      className="relative z-2 w-full mb-4 sm:mb-16 rounded-lg cursor-pointer news-card overflow-hidden bg-white"
    >
      <div>
        <ContentfulImage className="w-full" image={newsItem?.preview_logo!} />
      </div>
      <div className="p-8">
        <h4 className="text-2xl font-bold my-4">{newsItem.title}</h4>
        <p className="mb-4 text-gray-700">{newsItem.preview?.preview}</p>
        <p className="text-xs text-gray-400">{newsItem.date}</p>
      </div>
    </a>
  );
};

const WaterfallColl = (props: ChildrenProps) => {
  return <div className="flex flex-col">{props.children}</div>;
};

const WaterfallFlow = ({ lists }: { lists: INewsItem[] }) => {
  const { isSmScreen } = useMediaScreen();
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
      {!isSmScreen && (
        <>
          {lists.map((item) => {
            return <NewsItem key={item} newsItem={item} />;
          })}
        </>
      )}
      {isSmScreen && (
        <>
          <WaterfallColl>
            {lists
              .filter((_, idx: number) => idx % 2 === 0)
              .map((item) => {
                return <NewsItem key={item} newsItem={item} />;
              })}
          </WaterfallColl>
          <WaterfallColl>
            {lists
              .filter((_, idx: number) => idx % 2 !== 0)
              .map((item) => {
                return <NewsItem key={item} newsItem={item} />;
              })}
          </WaterfallColl>
        </>
      )}
    </div>
  );
};

const NewsPage: FC<{ data: NewsPageQuery }> = ({
  data: { allContentfulNews },
}) => {
  const { t } = useTranslation();
  const { news } = usePageMetadata();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...news} />
      <SkewGradientBg
        id="gradient-news"
        gradientHeight={1048}
        className="sm:h-[1048px]"
      />
      <MainContainer className="mb-20">
        <PageTitleWithIcon title={t("新闻")} icon={<NewsIcon />} />
        <div className="flex flex-wrap relative">
          <div className="w-full sm:w-[460px]">
            <PageTitle>{t("让世界更加紧密相连")}</PageTitle>
            <PageDescribe className="mt-6">
              {t("关注我们的公司新闻，掌握行业最新动态")}
            </PageDescribe>
          </div>
          <div className="relative sm:ml-16 mt-8 sm:mt-0">
            <img className="sm:hidden" src={NewsCover} alt="" />
            <div className="sm:absolute z-30 sm:-mt-28 sm:-ml-24 hidden sm:block">
              <Tilt
                className="Tilt max-w-none w-full sm:w-[600px] sm:h-[520px]"
                options={{
                  max: 15,
                  reverse: true,
                  speed: 1500,
                  perspective: 2000,
                }}
              >
                <SVGNewsCoverBack
                  className="absolute"
                  style={{ left: "0px", top: "138px", width: "597px" }}
                />
                <div className="Tilt-inner">
                  <SVGNewsCoverFront
                    className="absolute"
                    style={{ left: "45px", top: "30px", width: "520px" }}
                  />
                </div>
              </Tilt>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-[378px] mt-12 pb-12">
          <SearchInput />
          <div className="flex justify-between mt-7">
            <Button onClick={() => navigate("/contact")}>
              {t("联系销售")}
            </Button>
          </div>
        </div>
      </MainContainer>
      <SkewMainContainer fillClassName="bg-gray-50" className="z-20">
        <WaterfallFlow lists={allContentfulNews.nodes} />
      </SkewMainContainer>
      <LearnMoreView
        leftUrl={"/contact"}
        leftText={t("填写需求表格")}
        rightText={t("了解价格")}
        rightUrl={"/price"}
      />
      <Footer />
    </PageContainer>
  );
};

export default NewsPage;

export const query = graphql`
  query NewsPage($language: String!) {
    ...AllLangauages
    allContentfulNews(filter: { node_locale: { eq: $language } }) {
      nodes {
        id
        title
        linkUrl
        preview_logo {
          ...ContentfulImage200x200
        }
        preview {
          preview
        }
        date(formatString: "yyyy-MM-D")
      }
    }
  }
`;
